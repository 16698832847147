<template>
    <v-card
        style="display: inline-block; vertical-align: top"
        class="mr-4 mb-2"
        width="260px"
        color="#EAEAEA">
        <v-card-text class="pa-2">
            <h3>
                {{ lab.name }}
            </h3>
            <perfect-scrollbar
                class="scroll-height"
                :options="{ suppressScrollX: true }">
                <draggable
                    class="lab-group"
                    :value="labTests"
                    group="lab"
                    :disabled="!canEdit('LabTests')"
                    @change="onCardChanged($event, lab.id)">
                    <div v-for="(labTest, index) in labTests" :key="index">
                        <lab-test :lab-test="labTest" v-on="$listeners" />
                    </div>
                </draggable>
            </perfect-scrollbar>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn
                v-if="canEdit('LabTests')"
                color="tertiary"
                dark
                @click="$emit('add', lab.id)">
                Add Lab Test
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

export default {
    components: {
        draggable,
        labTest: () => import("./LabTest"),
    },
    props: {
        lab: {
            type: Object,
            default: null,
        },
        labTests: {
            type: Array,
            default: () => [],
        },
        filter: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isAdding: false,
        };
    },

    methods: {
        onCardChanged(value, labId) {
            if (!this.isEmpty(value.moved)) {
                this.move(value.moved.element, labId);
                return;
            }
            if (!this.isEmpty(value.added)) {
                this.move(value.added.element, labId);
            }
        },

        async move(labTest, labId) {
            this.$emit("move", { labTest, labId });
        },
    },
};
</script>

<style scoped>
    .scroll-height {
        height: 60vh;
    }

    .lab-group {
        min-height: 100%;
    }
</style>
